<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false"
    />
    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Chat Content -->
      <div class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block mr-1 d-lg-none">
                <feather-icon icon="MenuIcon" class="cursor-pointer" size="21" @click="mqShallShowLeftSidebar = true" />
              </div>
              <h6 v-if="activeChat.contact" class="mb-0">
                {{ activeChat.contact.fullName }}
              </h6>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar v-if="activeChat.contact" ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
          <b-overlay :show="chatLoading">
            <chat-log :chat-data="activeChat" />
          </b-overlay>
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-overlay v-if="activeChat.contact" :show="messageSending">
          <b-form class="chat-app-form" @submit.prevent="sendMessage">
            <b-input-group class="input-group-merge form-send-message mr-1">
              <b-form-input v-model="chatInputMessage" :placeholder="$t('chat.enter_message')" />
            </b-input-group>
            <b-button type="submit">
              {{ $t('chat.send') }}
            </b-button>
          </b-form>
        </b-overlay>
      </div>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts-loading="chatsContactsLoading || chatLoading"
        :chats-contacts="chatsContacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import { ref, onUnmounted, nextTick, computed } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import chatStoreModule from '@/store/modules/chat'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  name: 'Chat',

  components: {
    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  setup(_props, ctx) {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    const { $store } = ctx.root

    // Register module
    if (!$store.hasModule(CHAT_APP_STORE_MODULE_NAME)) $store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if ($store.hasModule(CHAT_APP_STORE_MODULE_NAME)) $store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const chatsContactsLoading = computed(() => $store.state.chat.chatsContactsLoading)
    const selectedOrganization = computed(() => $store.state.auth.selectedOrganization)

    const fetchChatAndContacts = async () => {
      $store.dispatch('app-chat/fetchChatsAndContacts', selectedOrganization.value).then(response => {
        chatsContacts.value = response
      })
    }

    fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const chatLoading = computed(() => $store.state.chat.chatLoading)
    const openChatOfContact = chatContact => {
      // Reset send message input value
      chatInputMessage.value = ''

      $store.dispatch('app-chat/getChat', chatContact).then(response => {
        activeChat.value = response

        // Set unseenMsgs to 0
        const contact = chatsContacts.value.find(c => c.id === chatContact.id)
        if (contact) contact.chat.unseenMsgs = 0

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const messageSending = computed(() => $store.state.chat.messageSending)
    const user = computed(() => $store.state.auth.user)
    const sendMessage = () => {
      if (!chatInputMessage.value) return
      const payload = {
        contactId: activeChat.value.contact.id,
        message: chatInputMessage.value,
      }
      $store.dispatch('app-chat/sendMessage', payload).then(response => {
        const newMessageData = {
          message: chatInputMessage.value,
          time: new Date(),
          senderId: selectedOrganization.value.id,
          senderName: `${user.value.firstName} ${user.value.lastName}`,
          senderOrganizationName: selectedOrganization.value.name,
        }

        activeChat.value.chat.chat.push(newMessageData)

        // Reset send message input value
        chatInputMessage.value = ''

        // Set Last Message for active contact
        const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
        contact.chat.lastMessage = newMessageData

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      $store.dispatch('app-chat/getProfileUser').then(response => {
        profileUserData.value = response.data
        shallShowUserProfileSidebar.value = true
      })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if ($store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      chatsContactsLoading,
      chatLoading,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,
      messageSending,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';
</style>
